<template>
  <div class="wrap">
    <div class="stepper_container">
      <Stepper :step="2" />
    </div>
    <div class="method">
      <div class="method__title">請選擇發票</div>
    </div>
    <div class="line_pay">
      <div class="input__wrap">
        <label>發票選項</label>
        <div class="carrier_select_bar">
          <div class="carrier__selected" @click="activeSelect = true">
            {{ carrierSelect.title }}
            <img src="@/assets/icon_downward.svg" alt />
          </div>
          <v-slide-y-reverse-transition>
            <div class="carrier_list" v-show="activeSelect">
              <div
                class="carrier_item"
                :class="{ active: carrierSelect.value === typeObj.value }"
                v-for="typeObj in carrierList"
                :key="typeObj.value"
                @click="carrierSelectHandler(typeObj)"
              >
                {{ typeObj.title }}
              </div>
            </div>
          </v-slide-y-reverse-transition>
        </div>
      </div>
      <div class="input__wrap" v-show="carrierSelect.value == '2'">
        <label for="credit_number">載具條碼</label>
        <div class="input">
          <input
            v-model="carrierNum"
            type="text"
            oninput="if(value.length>8){value=value.slice(0,8)}"
            placeholder="請輸入載具條碼"
          />
        </div>
      </div>
      <div class="input__wrap pt-4" v-if="carrierSelect.value != '3'">
        <label for="credit_number">統一編號</label>
        <div class="input">
          <input
            v-model="uniformNum"
            type="text"
            oninput="if(value.length>8){value=value.slice(0,8)}"
            placeholder="(選填)"
          />
        </div>
      </div>
    </div>
    <div
      class="nextBtn"
      @click="next()"
      :class="{ notReady: !check || waiting }"
      v-preventRepeat
    >
      {{ waiting ? "請稍候..." : "確認付款" }}
    </div>
    <form :action="PAY_URL" method="post" ref="toPay"></form>
  </div>
</template>

<script>
import Stepper from "../../components/訂單流程/Stepper";
export default {
  components: { Stepper },
  data() {
    return {
      carrierNum: "",
      // carrierType: null,
      // carrierList: ["會員電子發票", "手機載具", "捐贈發票"],
      carrierList: [
        {
          title: "會員電子發票",
          value: "1",
        },
        { title: "手機載具", value: "2" },
        { title: "捐贈發票", value: "3" },
      ],
      carrierSelect: {
        title: "會員電子發票",
        value: "1",
      },
      uniformNum: "",
      activeSelect: false,
      waiting: false,
    };
  },
  created() {
    if (!this.$store.state.orderCreater.ProductID) {
      return this.$router.replace("/");
    }
  },
  computed: {
    check() {
      if (this.carrierSelect.value == "2" && !this.carrierNum) {
        return false;
      }
      if (this.uniformNum && !this.uniformNum.match(/^\d{8}$/)) {
        return false;
      }
      return true;
    },
    PAY_URL() {
      return process.env.VUE_APP_LinePay;
    },
  },
  methods: {
    next() {
      if (!this.check) return alert("資料格式有誤喔!");
      let orderObj = this.$store.state.orderCreater;
      orderObj.CarrierType = this.carrierSelect.value;
      if (this.carrierSelect.value == "2") {
        orderObj.CarrierNum = this.carrierNum;
        if (orderObj.CarrierNum == null) {
          this.carrierSelect.value = 3; // TBD
        }
      } else {
        orderObj.CarrierNum = null;
      }
      orderObj.UniformNum = this.uniformNum || null;
      //如果有ReceiverMemberId則不用帶AstrolabeFileId
      let ProductForm = null;

      if (
        orderObj.Code == "HouseInterpretation" &&
        !orderObj.ReceiverMemberId
      ) {
        //宮位解讀
        ProductForm = JSON.stringify({
          AstrolabeFileId: parseInt(orderObj.Astrolabe.AstrolabeFileId),
        });
      } else if (orderObj.Code == "Synastry") {
        //雙人和盤
        ProductForm = JSON.stringify({
          SynastryDto: orderObj.SynastryDto,
        });
      }

      if (
        orderObj.CarrierType == "0" &&
        !this.carrierNum.match(/^\/[0-9A-Z.+-]{7}$/)
      ) {
        orderObj.CarrierType = "1";
        orderObj.CarrierNum = null;
      }
      //整合參數
      let Arg = {
        ...orderObj,
        ProductForm,
      };
      delete Arg.Astrolabe;
      delete Arg.SynastryDto;
      delete Arg.Code;

      this.waiting = true;
      console.log(Arg);
      // 呼叫購買API
      this.$API
        .POST_Order(Arg)
        .then((res) => {
          let dataString = res.Data;
          let dataObj = JSON.parse(this.$API.Decrypt(dataString));
          this.formPost(dataObj);
        })
        .catch((error) => {
          this.waiting = false;
        });
    },
    formPost(data) {
      try {
        let form = this.$refs.toPay;
        for (let item in data) {
          let opt = document.createElement("textarea");
          opt.name = item;
          opt.value = data[item];
          opt.style.display = "none";
          form.appendChild(opt);
        }
        form.submit();
      } catch (error) {
        alert(
          `跳轉付款失敗，請重整網頁後再嘗試購買，如多次無法成功，請至問題回報進行回報喔！錯誤訊息(可截圖此訊息上傳問題回報)：${error}`
        );
      }
    },
    carrierSelectHandler(typeObj) {
      if (typeObj.value !== "2") {
        this.uniformNum == null;
      }
      this.carrierSelect = typeObj;
      this.activeSelect = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.wrap {
  margin-top: 40px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mobile {
    margin-top: 0;
  }
  .stepper_container {
    width: 566px;
    margin-bottom: 73px;
  }
  .method {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    @include mobile {
      margin-top: 0px;
    }
    &__title {
      color: #9b9b9b;
      font-size: 36px;
      line-height: 52px;
      margin-bottom: 60px;
      @include mobile {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 40px;
      }
    }
    &__radio {
      @include mobile {
        /* padding-left: 40px; */
      }
      &__item {
        display: flex;
        align-items: center;
        color: $secondary-color-1;
        font-size: 36px;
        margin-bottom: 72px;
        line-height: 1;
        cursor: pointer;
        img {
          height: 30px;
        }
        @include mobile {
          font-size: 24px;
          margin-bottom: 32px;
          img {
            height: 19px;
          }
        }
        &__btn {
          width: 28px;
          height: 28px;
          background: #f0f2f4;
          box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
            4px 4px 25px rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          margin-right: 20px;
          @include center;
          @include mobile {
            width: 22px;
            height: 22px;
            margin-right: 12px;
          }
          &.active .inner {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $secondary-color-1;
            @include mobile {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
.nextBtn {
  @include btn-default(232px, 56px);
  margin-top: 51px;
}
//
//LinePAY專區
.line_pay {
  max-width: 375px;
  padding: 0 37px;
}
//checkbox
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  & > span {
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    background: #f0f2f4;
    box-shadow: -1px -1px 4px #ffffff, 2px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    @include center;
    margin-right: 10px;
  }
  font-size: 18px;
  letter-spacing: 0.06em;
  color: #9b9b9b;
  p {
    margin-bottom: 0;
  }
}
.input__wrap {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  label {
    font-size: 20px;
    line-height: 30px;
    color: #9b9b9b;
    padding-right: 20px;
    flex-shrink: 0;
  }
  .input {
    flex: 1;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0;
    border-bottom: 1px solid #9b9b9b;
    font-size: 20px;
    line-height: 30px;
    color: $secondary-color-1;
    flex-grow: 1;
    &::placeholder {
      color: #d9d9d9;
    }
    &:focus {
      border-bottom: 1px solid #9b9b9b;
      outline: none;
    }
  }

  .credit_expir-s {
    padding: 4px 6px 4px 0;
    border-bottom: 1px solid #9b9b9b;
    font-size: 20px;
    line-height: 30px;
    color: #d9d9d9;
  }
  .toast {
    position: relative;
    cursor: pointer;
    @include center;
    p {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-52%, -120%);
      width: 172px;
      height: auto;
      border-radius: 10px;
      background-color: #c4c4c4;
      padding: 4px 6px;
      color: #9b9b9b;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      background: #f0f2f4;
      box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
        2px 2px 6px rgba(0, 0, 0, 0.25);
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 100%);
        border-top: 12px solid #f0f2f4;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
      }
    }
  }
}
.carrier_select_bar {
  position: relative;
  width: 185px;
  font-size: 18px;
  line-height: 26px;
  color: #9b9b9b;
  .carrier__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f2f4;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 7px 16px;
    color: $secondary-color-1;
    cursor: pointer;
    & > img {
      width: 18px;
    }
  }
  .carrier_list {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    background: #f0f2f4;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding-bottom: 16px;
    overflow: hidden;
    .carrier_item {
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #d9d9d9;
      }
      &.active {
        color: $secondary-color-1;
      }
    }
  }
}
.carrierNum {
  width: 200px;
  input {
    display: flex;
    width: 100%;
    margin-left: auto;
    box-sizing: border-box;
    padding: 4px 0;
    border-bottom: 1px solid #9b9b9b;
    font-size: 20px;
    line-height: 30px;
    color: $secondary-color-1;
    flex-grow: 1;
    letter-spacing: 0.2rem;
    @include mobile {
      width: 100%;
      margin-left: 0;
    }
    &::placeholder {
      color: #d9d9d9;
    }
    &:focus {
      border-bottom: 1px solid #9b9b9b;
      outline: none;
    }
  }
}
</style>
